<template>
  <HpAnchor
    class="action-link"
    v-if="!isSaved"
    @click.prevent="add"
    :id="`save-plan-${medicareId}`"
  >
    <HpIcon name="heart-outline" class="mr-1" />
    <span class="hidden xs:flex"> {{ minimal ? 'Save' : 'Save this Plan' }} </span>
    <span class="flex xs:hidden"> Save </span>
  </HpAnchor>
  <HpAnchor
    class="action-link"
    v-else
    @click.prevent="remove"
    :id="`remove-saved-plan-${medicareId}`"
  >
    <HpIcon name="heart-filled" class="mr-1" />
    Saved
  </HpAnchor>
</template>

<script lang="ts" setup>
  import { ProfileStore } from '~/stores/profile'

  const props = withDefaults(
    defineProps<{
      medicareId: string
      year: number
      type?: 'favorite' | 'save'
      eventData: (() => Record<string, any>) | Record<string, any> | null | undefined
      minimal: boolean
    }>(),
    {
      type: 'save'
    }
  )

  const { medicareId, year } = toRefs(props)

  const profile = ProfileStore.use()

  const currentSaved = computed<string[]>(() => profile.savedPlans[year.value] ?? [])

  const isSaved = computed(() => currentSaved.value.includes(medicareId.value))

  const { maybePushToSave } = PushToSave.use('saved-plan', 'push-to-save-saved-plan')

  const add = () => {
    if (!isSaved.value) {
      profile.addSavedPlan(year.value, medicareId.value)
      maybePushToSave()
    }
  }

  const remove = () => {
    if (isSaved.value) {
      profile.removeSavedPlan(year.value, medicareId.value)
    }
  }
</script>

<style scoped></style>
